.dark {
    .links li {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .link {
        color: #C8F7C5;
    }

    .link:hover {
        color: #C8F7C5;
    }
}

.light {
    .link {
        color: blue;
    }

    .link:hover {
        color: blue;
    }
}

.links {
    list-style-type: none;
    padding: 0;
}

.links li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 10px;
}

.link {
    font-size: large;
    color: #C8F7C5;
    text-decoration: underline;
}

.link:hover {
    font-size: large;
    color: #C8F7C5;
    text-decoration: underline;
}

.remove_event {
    width: 30px;
    height: 30px;
}