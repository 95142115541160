.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.info {
    display: flex;
    justify-content: center;
    color: white;
    margin-bottom: 0.5rem;
}

.qr-content {
    width: 320px;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    touch-action: none;
    -ms-touch-action: none;
	margin-bottom: 2em;
}

.qr-frame {
    top: 20px;
    width: 100%;
}
