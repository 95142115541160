.logo {
    margin: 20px;
    max-width: 200px;
    max-height: 100px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.qr-content {
    width: 320px;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    touch-action: none;
    -ms-touch-action: none;
	margin-bottom: 2em;
}

.qr-frame {
    top: 20px;
    width: 100%;
}

.status-actions {
    width: 320px;
    justify-content: space-between;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
}

.action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.links {
    padding: 0;
    list-style: none;
}

.links li {
    font-size: 16px;
    padding: 10px;
}

.error {
    margin-bottom:10px;
}

.info {
    display: flex;
    justify-content: center;
    color: white;
    // margin-top: 2rem;
    margin-bottom: 0.5rem;
}
