.user-panel {
    color: white;
    font-size: large;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 160; 
    background: 'white';
}

.status {
    color: white;
    font-size: large;
    display: flex;
    flex-direction: row;
    align-items: center;
}