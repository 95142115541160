.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.logo {
    margin: 20px;
    max-width: 200px;
    max-height: 100px;
}

.header .event {
    text-align: center;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 1em;
    color: white;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
}

.action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.links {
    padding: 0;
    list-style: none;
}

.links li {
    font-size: 16px;
    padding: 10px;
}