.rs-message-success .rs-message-body {
	color: green;
}

.rs-message-error .rs-message-body {
	color: white;
}

.rs-message {
	margin-bottom: 1em;
}

.swal2-popup {
	background: #004742 !important;
}
