.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    margin-right: 32px;
    margin-left: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.logo {
    margin: 20px;
    max-width: 200px;
    max-height: 100px;
}

.info {
    color: white;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
	font-size: larger;
    font-weight: bold;
}

.select-buttons {
	margin-top: 2em;
}

.hr {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin-top: 2rem;
    margin-bottom: 2rem;
 } 
 
 .hr span { 
    color: white;
    background:#004742; 
    padding: 0 10px;
 }

 .user-scanner-input {
    width: 65%;
    margin-right: 1rem;
	color: #1b1a1a;
 }

 .name-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
 }
