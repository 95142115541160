.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    margin-right: 32px;
    margin-left: 32px;
}

.logo {
    margin: 20px;
    max-width: 200px;
    max-height: 100px;
}

.qr-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.qr-content {
    width: 320px;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    touch-action: none;
    -ms-touch-action: none;
}

.video {
    width: 440px;
    top: -60px;
    position: absolute
}

.qr-frame {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 50px solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 100%;
    height: 100%;
}

.info {
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.hr {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin-top: 2rem;
    margin-bottom: 2rem;
 } 
 
 .hr span { 
    color: white;
    background:#004742; 
    padding: 0 10px;
 }

 .code-container {
    display: flex;
    flex-direction: row;
	margin-bottom: 1em;
 }

 .code-input {
    width: 65%;
    margin-right: 1rem;
	color: #1b1a1a;
 }

 .links {
    list-style-type: none;
    padding: 0;
 }

 .links li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
 }

 .link {
    font-size: large;
    color: #C8F7C5;
    text-decoration: underline;
 }

 .link:hover {
    font-size: large;
    color: #C8F7C5;
    text-decoration: underline;
 }
 